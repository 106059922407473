import React, { useState } from 'react'
import 'twin.macro'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import {
  newFast1hCollection,
  mousseCollection,
  products,
} from '../../utils/products'
import {
  getShowUpProductsGroups,
  groupIdMap,
} from '../BanhSinhNhatPage/ProductGroups'
import { map } from 'lodash'

const productGroups = getShowUpProductsGroups([
  ...newFast1hCollection,
  ...mousseCollection,
  ...products,
])
const groupNames = map(productGroups, 'name')

const Sidebar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    sanPham: true,
  })

  const toggleDropdown = (section) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }))
  }

  return (
    <div tw="h-full w-full bg-white font-savor-cake-primary-lexend border border-savor-cake-primary-mint-0">
      <ul tw="mt-2">
        <RedirectBar content="TRANG CHỦ" href="/" />
        <RedirectBar content="GIỚI THIỆU" href="/#features" />
        <li tw="flex justify-between items-center flex-col">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            tw="flex justify-between w-full hover:bg-gray-100 cursor-pointer p-2 text-savor-cake-primary-green-avocado-100 font-bold"
            onClick={() => toggleDropdown('sanPham')}
            role="button"
            tabIndex={0}
          >
            SẢN PHẨM{isDropdownOpen.sanPham ? <FaAngleUp /> : <FaAngleDown />}
          </div>

          {isDropdownOpen.sanPham && (
            <ul tw="pl-4 w-full">
              {groupNames.map((name) => {
                return (
                  <li tw="p-2 hover:bg-gray-100 cursor-pointer w-full">
                    <a
                      href={`/?viewId=${groupIdMap[name]}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      tw="text-black! block w-full"
                    >
                      {name}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </li>
        <RedirectBar
          content="BÁNH VẼ/ORDER"
          href="/#scake-savor-cake-main-order-section"
        />
        <RedirectBar
          content="COMBO 2 BÁNH"
          href="/#scake-savor-cake-main-combo-section"
        />
        <RedirectBar content="CHÍNH SÁCH" href="/#policy" />
        <RedirectBar content="CỬA HÀNG" href="/#google-map" />
        <RedirectBar
          content="FEEDBACKS"
          href="/#scake-savor-cake-main-feedback"
        />
        <RedirectBar content="HOẠT ĐỘNG XÃ HỘI" href="/#social-event" />
      </ul>
    </div>
  )
}

export default Sidebar

const RedirectBar = ({ content, href }) => {
  return (
    <li tw="p-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        tw="w-full text-savor-cake-primary-green-avocado-100! font-bold"
      >
        {content}
      </a>
    </li>
  )
}
