import React from 'react'
import tw, { styled } from 'twin.macro'
import Layout from '../components/Layouts/cakeDefault'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import MainSideBar from '../components/SideBar/MainSideBar'
import { StaticImage } from 'gatsby-plugin-image'

const SocialEvents = () => {
  return (
    <TailwindLayout>
      <Layout
        endPoint={'social-events'}
        title={'Savor Cake | Hoạt động Xã hội'}
      >
        <main tw="lg:max-w-7xl mx-auto pt-8 pb-8 px-4 lg:px-10 flex flex-col-reverse md:flex-row justify-start items-start w-full gap-3 md:gap-6 lg:gap-10 xl:gap-20 font-savor-cake-primary-lexend text-justify">
          <div tw="w-full overflow-hidden pb-10 text-base md:text-lg">
            <header>
              <div tw="text-left">
                <h1 tw="mt-1 mb-6">
                  Hoạt động Xã hội và Thiện nguyện cùng Savor Cake
                </h1>
                <div></div>
              </div>
            </header>

            <section tw="mb-4">
              <h3 tw="text-2xl font-bold mb-4">
                1. Chuỗi hoạt động thiện nguyện "Làm Bánh Cho Em"
              </h3>
              <Para>
                Chuỗi hoạt động thiện nguyện "Làm Bánh Cho Em" được thực hiện
                bởi thương hiệu bánh sinh nhật Savor Cake tại Làng trẻ em SOS Hà
                Nội với mong muốn mang lại những nụ cười tràn đầy hạnh phúc cho
                các em nhỏ. Chuỗi hoạt động này không chỉ dừng lại ở những chiếc
                bánh kem thơm ngon mà còn gửi gắm những món quà ý nghĩa, tạo nên
                những kỷ niệm đẹp và ấm áp cho các em trong ngày sinh nhật.
              </Para>
              <Para>
                Quý đầu tiên diễn ra vào ngày 24/03/2024, dành tặng cho các bạn
                nhỏ có sinh nhật trong tháng 1, 2 và 3. Những chiếc bánh sinh
                nhật được Savor Cake chuẩn bị không chỉ ngon miệng mà còn vô
                cùng đẹp mắt. Các em nhỏ đã có một ngày sinh nhật thật đặc biệt
                với bánh kem, quà tặng và những hoạt động vui chơi thật vui
                nhộn. Niềm vui và những nụ cười trên gương mặt các em là minh
                chứng rõ ràng nhất cho sự thành công của chương trình.
              </Para>
              <figure tw="flex flex-col items-center">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/images/events/lbce1.jpg"
                  alt='Hoạt động thiện nguyện "Làm Bánh Cho Em" quý I'
                />
                <Figcaption>
                  Hoạt động thiện nguyện "Làm Bánh Cho Em" quý I
                </Figcaption>
              </figure>
              <Para>
                Như đã hẹn, Savor quay trở lại Làng Trẻ Em SOS Hà Nội để tổ chức
                sinh nhật cho các em nhỏ có ngày sinh trong quý II (tháng 4,5 và
                6), đồng thời chúc mừng ngày Quốc tế Thiếu nhi 1/6. Những chiếc
                bánh kem lung linh và các món quà xinh xắn đã được trao tận tay
                các em và những người mẹ. Không khí tràn ngập niềm vui và sự ấm
                áp, tạo nên những kỷ niệm khó quên cho tất cả mọi người tham
                gia.
              </Para>
              <figure tw="flex flex-col items-center">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/images/events/lbce2.png"
                  alt='Hoạt động thiện nguyện "Làm Bánh Cho Em" quý II'
                />
                <Figcaption>
                  Hoạt động thiện nguyện "Làm Bánh Cho Em" quý II
                </Figcaption>
              </figure>
              <Para>
                Chuỗi chương trình "Làm Bánh Cho Em" với mục tiêu mang lại cho
                những bạn nhỏ có hoàn cảnh đặc biệt những chiếc bánh kem đầy ý
                nghĩa dịp sinh nhật, cũng như thể hiện sự quan tâm và mong muốn
                đóng góp một phần nhỏ vào các hoạt động xã hội nói chung và đối
                tượng trẻ em có hoàn cảnh đặc biệt nói riêng.
              </Para>
            </section>
            <section tw="mb-4">
              <h3 tw="text-2xl font-bold mb-4">
                2. Chương trình thiện nguyện "Hương Vị Yêu Thương”
              </h3>
              <Para>
                Savor Cake đã phát động một chiến dịch gây quỹ từ thiện vô cùng
                đặc biệt mang tên "Hương Vị Yêu Thương" từ việc bán sản phẩm
                bánh su kem dài. Mỗi hộp có giá ưu đãi trong chương trình thiện
                nguyện chỉ #25k/hộp 10 chiếc bánh su kem dài, có thể lựa chọn 2
                vị: Nhân socola hoặc Nhân trứng muối.
              </Para>
              <Para>
                Đối với mỗi hộp su kem được bán ra, toàn bộ doanh thu sẽ được
                Savor gửi vào QUỸ LÀNG TRẺ SOS - Tổ chức hoạt động vì trẻ em tại
                138 quốc gia và vùng lãnh thổ với vai trò là một tổ chức phát
                triển xã hội độc lập, phi chính phủ, phi chính trị và phi tôn
                giáo, bảo vệ và chăm sóc trẻ mồ côi, bị bỏ rơi hoặc không thể
                nhận được sự chăm sóc từ gia đình.
              </Para>
              <Para>
                Ngày 14/04, chương trình thiện nguyện “Hương Vị Yêu Thương” của
                Savor Cake chính thức khép lại, với tổng số lượng bánh su kem đã
                bán là 230 hộp và thu được số tiền là 5.575.000 đồng. Mang trong
                mình sứ mệnh lan toả hạnh phúc, toàn bộ số tiền thu được từ
                chương trình vừa qua đã được Savor gửi vào quỹ “Hoạt động phát
                triển trẻ em tại làng trẻ SOS” cho đối tượng là các bạn độ tuổi
                tiểu học vào ngày 17/04/2024.
              </Para>
              <figure tw="flex flex-col items-center">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/images/events/hvyt.png"
                  alt='Chương trình thiện nguyện "Hương Vị Yêu Thương” - Toàn bộ doanh thu bán bánh su kem sẽ chuyển vào quỹ từ thiện'
                />
                <Figcaption>
                  Chương trình thiện nguyện "Hương Vị Yêu Thương” - Toàn bộ
                  doanh thu bán bánh su kem sẽ chuyển vào quỹ từ thiện
                </Figcaption>
              </figure>
            </section>

            <section tw="mb-4">
              <h3 tw="text-2xl font-bold mb-4">
                3. Nhà tài trợ chương trình thiện nguyện "Giọt hồng gắn kết"
              </h3>
              <Para>
                Ngày hội hiến máu "Giọt Hồng Gắn Kết" mang thông điệp "Cùng nhau
                hiến máu - Chia sẻ yêu thương." Mỗi giọt máu trao đi không chỉ
                là một hành động cứu sống mà còn mang lại hy vọng và cơ hội mới
                cho những bệnh nhân đang cần máu. Sự kiện này đã thu hút rất
                nhiều tình nguyện viên tham gia, cùng nhau lan tỏa tình yêu
                thương và tinh thần nhân ái.
              </Para>
              <Para>
                Trong sự kiện này, Savor Cake đã đóng góp những chiếc bánh xinh
                xắn từ để tiếp sức cho các tình nguyện viên. Những chiếc bánh
                ngọt ngào không chỉ mang đến niềm vui mà còn là nguồn động viên
                tinh thần, giúp các bạn tình nguyện viên có thêm năng lượng để
                tiếp tục cống hiến trong những chương trình ý nghĩa tiếp theo.
                Savor tin rằng, mỗi chiếc bánh không chỉ là món quà vật chất mà
                còn là biểu tượng của sự tri ân và tình cảm mà chúng mình muốn
                gửi gắm đến những người tham gia hiến máu.
              </Para>
              <Para>
                "Giọt Hồng Gắn Kết" không chỉ là một sự kiện hiến máu thông
                thường mà còn là dịp để cộng đồng gắn kết, chia sẻ và cùng nhau
                lan tỏa những giá trị tốt đẹp. Savor Cake rất tự hào khi được là
                một phần của sự kiện này, cùng đóng góp vào việc xây dựng một
                cộng đồng nhân ái và đoàn kết. Savor hy vọng với những chiếc
                bánh ngọt ngào và tinh thần sẻ chia, "Giọt Hồng Gắn Kết" sẽ tiếp
                tục lan tỏa và thu hút nhiều người tham gia hơn nữa, tạo nên
                những điều kỳ diệu cho cuộc sống.
              </Para>
              <figure tw="flex flex-col items-center">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/images/events/ghgk.png"
                  alt="Những bạn tình nguyện viên nhiệt huyết cùng chiếc bánh ngọt ngào của Savor Cake"
                />
                <Figcaption>
                  Những bạn tình nguyện viên nhiệt huyết cùng chiếc bánh ngọt
                  ngào của Savor Cake
                </Figcaption>
              </figure>
            </section>
          </div>
          <div tw="w-full md:w-1/3 relative h-full">
            <MainSideBar />
          </div>
        </main>
      </Layout>
    </TailwindLayout>
  )
}

export default SocialEvents

const Para = styled.p`
  ${tw`mb-4`}
`

const Figcaption = styled.figcaption`
  ${tw`text-sm lg:text-base text-center py-2`}
`
